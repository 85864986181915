import React, { useEffect, useState } from 'react';
import styles from './AffData.module.css';

// Type definitions
interface MonthlyStats {
    currentMonthCheckoutSessions?: number;
    currentMonthMoneyMade?: number;
    currentMonthSubscriptions?: number;
    currentMonthUniqueCheckoutSessions?: number;
    currentMonthUniqueVisits?: number;
    currentMonthVisitors?: number;
}

interface ReferredUser {
    email: string;
    eventType: string;
    userID: string;
}

interface AffiliateData {
    activeSubs: number;
    affiliateId: string;
    monthlyStats: Record<string, MonthlyStats>;
    referredUsers: ReferredUser[];
    userEmail: string;
    userId: string;
}

// Sample data
const affData = [
    {
        "activeSubs": 0,
        "affiliateId": "00a320ab-a5a9-41d6-93a8-ece4a4e5445b",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "Email not found",
        "userId": "3W8GxUnnd1cIoQjjctp3TEmhe2r2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "0deb52f7-85c4-4a2e-addf-1fa90e291e4d",
        "monthlyStats": {
            "2024-11": {
                "currentMonthCheckoutSessions": 2,
                "currentMonthUniqueCheckoutSessions": 2,
                "currentMonthUniqueVisits": 9,
                "currentMonthVisitors": 29
            },
            "2024-12": {
                "currentMonthUniqueVisits": 54,
                "currentMonthVisitors": 107
            },
            "2025-01": {
                "currentMonthCheckoutSessions": 7,
                "currentMonthMoneyMade": 89.99,
                "currentMonthSubscriptions": 1,
                "currentMonthUniqueCheckoutSessions": 3,
                "currentMonthUniqueVisits": 10,
                "currentMonthVisitors": 25
            }
        },
        "referredUsers": [
            {
                "email": "crdmediagroup@gmail.com",
                "eventType": "subscription",
                "userID": "PkgcaY6QjDV3uvlOVZdv4ecV4Ro2"
            }
        ],
        "userEmail": "Email not found",
        "userId": "UJj8oVysdWUzArTnB9q4wANMjTx2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "21dcbb04-dc01-4d97-93b4-c198bd50ec0f",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "baichstefan@gmail.com",
        "userId": "e782ZeLJQEPPBWVlhUBjLSqihAj1"
    },
    {
        "activeSubs": 6,
        "affiliateId": "2af3cdea-5b80-4eb8-8cd9-1ec223cf23d3",
        "monthlyStats": {
            "2024-12": {
                "currentMonthCheckoutSessions": 17,
                "currentMonthMoneyMade": 555.87,
                "currentMonthSubscriptions": 16,
                "currentMonthUniqueCheckoutSessions": 8,
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 38
            },
            "2025-01": {
                "currentMonthCheckoutSessions": 1,
                "currentMonthMoneyMade": 89.99,
                "currentMonthSubscriptions": 1,
                "currentMonthUniqueCheckoutSessions": 1,
                "currentMonthUniqueVisits": 2,
                "currentMonthVisitors": 6
            }
        },
        "referredUsers": [
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "vkAqLx37a3VLJm7QnXAAIeRyXZ82"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "dhLz59cWHJMbgBgm8W2ChUaOjf53"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "CXKZdlRjJVWPd8wn5IHG8vVenGz2"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "WLGEEQTiPVPTOQfbWQZtxsmAkDh2"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "qn9Jva3woQNlIpd1fY8Mcr44LXC3"
            },
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "vkAqLx37a3VLJm7QnXAAIeRyXZ82"
            },
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "CXKZdlRjJVWPd8wn5IHG8vVenGz2"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "vkAqLx37a3VLJm7QnXAAIeRyXZ82"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "vkAqLx37a3VLJm7QnXAAIeRyXZ82"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "CXKZdlRjJVWPd8wn5IHG8vVenGz2"
            },
            {
                "email": "choppedchin358@gmail.com",
                "eventType": "subscription",
                "userID": "YH4gSrRZC7QZczFYAMZ3ZMMiGGR2"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "KbMP6ehTbtXz729ttaQDRZJtczw2"
            },
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "CXKZdlRjJVWPd8wn5IHG8vVenGz2"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "UUdqqcNY0ud19AHY2ghz6ZTEOBW2"
            },
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "CXKZdlRjJVWPd8wn5IHG8vVenGz2"
            },
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "vkAqLx37a3VLJm7QnXAAIeRyXZ82"
            },
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "vkAqLx37a3VLJm7QnXAAIeRyXZ82"
            },
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "CXKZdlRjJVWPd8wn5IHG8vVenGz2"
            }
        ],
        "userEmail": "Email not found",
        "userId": "dhLz59cWHJMbgBgm8W2ChUaOjf53"
    },
    {
        "activeSubs": 0,
        "affiliateId": "302417e6-2f47-4c2a-8243-6e06695eaa1a",
        "monthlyStats": {
            "2024-11": {
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 1
            }
        },
        "referredUsers": [],
        "userEmail": "akatergi20@gmail.com",
        "userId": "Q4VNXkJsR2VOOQNkBhVm0oFUhYd2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "39d8102d-b05d-4c8a-9837-8118d35ff89a",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "egor98business@gmail.com",
        "userId": "luHOZH9RjAOZhYCtAxGmSBKAMN42"
    },
    {
        "activeSubs": 0,
        "affiliateId": "3cca464a-55cf-4dcf-b42f-c1631940f6a1",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "adikbek228@gmail.com",
        "userId": "41jDKVYoSfQnscawQiU33Q4yZkp1"
    },
    {
        "activeSubs": 1,
        "affiliateId": "3f761620-d221-43d8-8ab5-1679d83eb1e0",
        "monthlyStats": {
            "2025-01": {
                "currentMonthCheckoutSessions": 1,
                "currentMonthMoneyMade": 89.99,
                "currentMonthSubscriptions": 1,
                "currentMonthUniqueCheckoutSessions": 1,
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 2
            }
        },
        "referredUsers": [
            {
                "email": "adeltesttester@gmail.com",
                "eventType": "subscription",
                "userID": "dvfujJED1fP8yo4YKuhHyKWJWdX2"
            }
        ],
        "userEmail": "adeltesttester@gmail.com",
        "userId": "dvfujJED1fP8yo4YKuhHyKWJWdX2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "49dca731-49de-416b-bdd8-9381789653d3",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "kasaki909080@gmail.com",
        "userId": "hf38eOCmZFYODc64cjPkE1W6fHx1"
    },
    {
        "activeSubs": 0,
        "affiliateId": "4dea856d-d7c9-4a39-95fd-5eb42944d0e8",
        "monthlyStats": {
            "2025-01": {
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 3
            }
        },
        "referredUsers": [],
        "userEmail": "thefuturelooksbrighter316@gmail.com",
        "userId": "nRtvwutMYdRmtQ2OgfLUDR1Dz842"
    },
    {
        "activeSubs": 0,
        "affiliateId": "4fd297d6-0cbe-408c-b460-182ffcd08947",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "birukh942@gmail.com",
        "userId": "cg8zrmXsohaf6veL24JWFv9hgfh1"
    },
    {
        "activeSubs": 0,
        "affiliateId": "5110df26-7161-414b-8eda-b1e80bcab8d0",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "roslov22@gmail.com",
        "userId": "Vi6PnriWyISeXc549UDA4ZhdBEq2"
    },
    {
        "activeSubs": 1,
        "affiliateId": "5ff178ba-64d4-4955-93cd-f739184f0409",
        "monthlyStats": {
            "2025-01": {
                "currentMonthCheckoutSessions": 9,
                "currentMonthMoneyMade": 14.99,
                "currentMonthSubscriptions": 1,
                "currentMonthUniqueCheckoutSessions": 4,
                "currentMonthUniqueVisits": 21,
                "currentMonthVisitors": 28
            }
        },
        "referredUsers": [
            {
                "email": "metastudytester000@gmail.com",
                "eventType": "subscription",
                "userID": "cwtEzB3TwDSGsAsiMgGwhItGlNo2"
            }
        ],
        "userEmail": "halc.dev@gmail.com",
        "userId": "YfEgtba8aSTMDG8KgQ5QFYHxO8h1"
    },
    {
        "activeSubs": 0,
        "affiliateId": "6933a9c8-d4c3-4ba8-8bd9-20f3ff38b1b6",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "mohsinjamilkhan4@gmail.com",
        "userId": "JSC9xEVspCU4yTMl2M64M0flMd42"
    },
    {
        "activeSubs": 0,
        "affiliateId": "6ae1ca86-7cc8-4650-9748-c8cd07e6555b",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "srverva@gmail.com",
        "userId": "ZPxfQLmdVVSyVF0zrY9s2KpQNUZ2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "7459fa09-1ab8-415a-a751-2155a2a556a2",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "cryptodrbanks@gmail.com",
        "userId": "8tq6M4443SYt66vzDa05VSlsaVX2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "7dc68f41-7147-4c8e-b17c-7f69aaea4fd0",
        "monthlyStats": {
            "2024-12": {
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 7
            }
        },
        "referredUsers": [],
        "userEmail": "Email not found",
        "userId": "tTApFcqSCybnE9mWITLF66EzvR42"
    },
    {
        "activeSubs": 0,
        "affiliateId": "828511ff-b260-4a35-b0ef-4890c9928949",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "jonathangiancarlo1@gmail.com",
        "userId": "WWTiKkT0CLdumIClXVhTe38WusS2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "8aec4921-ac71-4398-8fe7-352bd2357cd6",
        "monthlyStats": {
            "2025-01": {
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 6
            }
        },
        "referredUsers": [],
        "userEmail": "aiunmasked999@gmail.com",
        "userId": "9yyoHTsZkTbocHcbnPMQui0VM2h1"
    },
    {
        "activeSubs": 0,
        "affiliateId": "8c3c906a-cfab-4603-9b46-19966588188a",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "dpenciu@gmail.com",
        "userId": "lHlDnR70bJYUW1tBXoo5lOkEidI2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "9b9b042b-68ac-4015-a293-bdfdb81cbaed",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "trentjackson1111@gmail.com",
        "userId": "RIhZFutHLpYIk1hmKU4g06SgcpV2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "9d77a651-7874-47d7-87fc-42cb85393122",
        "monthlyStats": {
            "2024-12": {
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 4
            }
        },
        "referredUsers": [],
        "userEmail": "Email not found",
        "userId": "d3XzWZENesb5SA3wSvwnHsvz2GO2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "a07e4584-0b88-4566-8e94-aa2182bc01bb",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "stanekjan613@gmail.com",
        "userId": "BRYJWSUbKDRnCl3DzxmN7sR8SQF2"
    },
    {
        "activeSubs": 2,
        "affiliateId": "a12",
        "monthlyStats": {
            "2024-11": {
                "currentMonthCheckoutSessions": 6,
                "currentMonthSubscriptions": 2,
                "currentMonthUniqueCheckoutSessions": 2,
                "currentMonthUniqueVisits": 4,
                "currentMonthVisitors": 24
            },
            "2024-12": {
                "currentMonthCheckoutSessions": 1,
                "currentMonthSubscriptions": 2,
                "currentMonthUniqueCheckoutSessions": 1,
                "currentMonthUniqueVisits": 2,
                "currentMonthVisitors": 8
            }
        },
        "referredUsers": [
            {
                "email": "Email not found",
                "eventType": "resubscribe",
                "userID": "lkhp3OBKk4dBGN87trTmKQTXhoz2"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "lkhp3OBKk4dBGN87trTmKQTXhoz2"
            },
            {
                "email": "vidgptbusiness@gmail.com",
                "eventType": "subscription",
                "userID": "zyaJyfW7RgNJaWtkwuoFKndqvNE3"
            },
            {
                "email": "Email not found",
                "eventType": "subscription",
                "userID": "EGqfhOwLTiWaCmlWQfnuhhNUap63"
            }
        ],
        "userEmail": "vidgptbusiness@gmail.com",
        "userId": "zyaJyfW7RgNJaWtkwuoFKndqvNE3"
    },
    {
        "activeSubs": 0,
        "affiliateId": "a3274460-0055-495d-bf5c-dd466cbaf6af",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "nayimr02@gmail.com",
        "userId": "V2JKkRU8H8QwGScSXdXZ4oNz7pT2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "a7ea258a-3355-48ae-86ef-0e7561d79523",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "itschuckhere@gmail.com",
        "userId": "rSw7nB9KTXapOLlVKcTeQ1TR5UY2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "ab1f9cd1-7b0b-4d95-9750-df7bc82f66e4",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "gebremedhinyhaish@gmail.com",
        "userId": "cld6t79ThUUgxF6fOAq7Txfnnf72"
    },
    {
        "activeSubs": 0,
        "affiliateId": "b0fcbe9e-3b28-4e58-88d0-7bd3fe2c7f3b",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "kenyop123@gmail.com",
        "userId": "IuCLdE5nrOZRzTZVzB3UndbYMIv1"
    },
    {
        "activeSubs": 1,
        "affiliateId": "b197cd8f-4cad-45a6-9583-db1caf7270e7",
        "monthlyStats": {
            "2025-01": {
                "currentMonthCheckoutSessions": 1,
                "currentMonthMoneyMade": 14.99,
                "currentMonthSubscriptions": 1,
                "currentMonthUniqueCheckoutSessions": 1,
                "currentMonthUniqueVisits": 1,
                "currentMonthVisitors": 1
            }
        },
        "referredUsers": [
            {
                "email": "alston1121@gmail.com",
                "eventType": "subscription",
                "userID": "fN3l7T5GvzgAIvsktClC5crNLaA3"
            }
        ],
        "userEmail": "alston1121@gmail.com",
        "userId": "fN3l7T5GvzgAIvsktClC5crNLaA3"
    },
    {
        "activeSubs": 0,
        "affiliateId": "b7ccb65a-92a2-4787-83b3-14020b58b192",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "Email not found",
        "userId": "umorkrctBWQ9QzyuzGmjIbFaVmh1"
    },
    {
        "activeSubs": 0,
        "affiliateId": "c087857b-0911-4ab1-a4f7-0090baaff49e",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "linkinpark761102@gmail.com",
        "userId": "Q9noSbXFIGPC2KxJt5QOgf3BHKw2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "c5473ccc-770d-4878-8bec-bb3e2b79464a",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "arafatsalesbg@gmail.com",
        "userId": "MreMxZc8AXcJjQLgA2j0l92k67m2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "c840c4b4-9695-4762-ac3e-bf1c00e70b13",
        "monthlyStats": {
            "2025-01": {
                "currentMonthCheckoutSessions": 9,
                "currentMonthUniqueCheckoutSessions": 5,
                "currentMonthUniqueVisits": 138,
                "currentMonthVisitors": 200
            }
        },
        "referredUsers": [],
        "userEmail": "prioritylearn@gmail.com",
        "userId": "1UyWCYMFgZZnGzH1bVMlqQ8eM6x2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "cc55db49-f46c-483a-9a6b-36905819147b",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "iamlegend1136@gmail.com",
        "userId": "Se7UaxXOsONugEpeK8TNZrChZvJ2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "d41d3654-ffb5-42e8-b129-8bac6463d09f",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "Email not found",
        "userId": "FFv6SBgvi9eEzNKprVrymj4vE523"
    },
    {
        "activeSubs": 0,
        "affiliateId": "e42ed09f-d601-4258-9b3c-240a731d2446",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "moneyeon@gmail.com",
        "userId": "VUECHbIJjDflBvgVCknsZomQ4w72"
    },
    {
        "activeSubs": 0,
        "affiliateId": "ebf2499e-bdf9-4c9c-beab-61be62023633",
        "monthlyStats": {
            "2025-01": {
                "currentMonthCheckoutSessions": 7,
                "currentMonthUniqueCheckoutSessions": 4,
                "currentMonthUniqueVisits": 2,
                "currentMonthVisitors": 4
            }
        },
        "referredUsers": [],
        "userEmail": "Email not found",
        "userId": "dWvPxyn6cZVfOvSgQKZzOrRSp0S2"
    },
    {
        "activeSubs": 0,
        "affiliateId": "f0a70cd5-f559-47e0-8485-9c41f61efcb3",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "spacecadetnari@gmail.com",
        "userId": "mlhg84avF9U3OmnZzh0BTXKTzJE3"
    },
    {
        "activeSubs": 0,
        "affiliateId": "f387e8d0-0221-4cdf-8f3c-126b72f9866e",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "mohsinjamilkhan4@gmail.com",
        "userId": "JSC9xEVspCU4yTMl2M64M0flMd42"
    },
    {
        "activeSubs": 0,
        "affiliateId": "fec36dab-1515-4cd7-8137-ff3d9c0765a9",
        "monthlyStats": {},
        "referredUsers": [],
        "userEmail": "Email not found",
        "userId": "H2u7PsTikoc1Rdfw2d9iDeVJfVC2"
    }
];

export default function AffData() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAffiliate, setSelectedAffiliate] = useState(null);

    // Calculate total stats
    const totalStats = affData.reduce((acc, curr) => ({
        activeSubs: acc.activeSubs + curr.activeSubs,
        totalMoneyMade: acc.totalMoneyMade + Object.values(curr.monthlyStats).reduce((sum, month) =>
            sum + (month.currentMonthMoneyMade || 0), 0),
        totalVisitors: acc.totalVisitors + Object.values(curr.monthlyStats).reduce((sum, month) =>
            sum + (month.currentMonthVisitors || 0), 0),
        totalReferrals: acc.totalReferrals + curr.referredUsers.length
    }), {
        activeSubs: 0,
        totalMoneyMade: 0,
        totalVisitors: 0,
        totalReferrals: 0
    });

    // Filter affiliates based on search
    const filteredAffiliates = affData.filter(affiliate =>
        affiliate.userEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        affiliate.affiliateId.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => { document.body.style.overflowY = 'scroll' }, [])

    return (
        <div className={styles.container}>
            {/* Header */}
            <header className={styles.header}>
                <div className={styles.headerContent}>
                    <h1 className={styles.headerTitle}>Affiliate Dashboard</h1>
                </div>
            </header>

            {/* Main Content */}
            <main className={styles.main}>
                {/* Stats Overview */}
                <div className={styles.statsGrid}>
                    <div className={styles.statCard}>
                        <div className={styles.statContent}>
                            <div className={styles.statIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-users "><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M22 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            </div>
                            <div className={styles.statInfo}>
                                <dt className={styles.statLabel}>Active Subscriptions</dt>
                                <dd className={styles.statValue}>{totalStats.activeSubs}</dd>
                            </div>
                        </div>
                    </div>

                    <div className={styles.statCard}>
                        <div className={styles.statContent}>
                            <div className={styles.statIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-dollar-sign "><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            </div>
                            <div className={styles.statInfo}>
                                <dt className={styles.statLabel}>Total Revenue</dt>
                                <dd className={styles.statValue}>${totalStats.totalMoneyMade.toFixed(2)}</dd>
                            </div>
                        </div>
                    </div>

                    <div className={styles.statCard}>
                        <div className={styles.statContent}>
                            <div className={styles.statIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-line-chart "><path d="M3 3v18h18"></path><path d="m19 9-5 5-4-4-3 3"></path></svg>
                            </div>
                            <div className={styles.statInfo}>
                                <dt className={styles.statLabel}>Total Visitors</dt>
                                <dd className={styles.statValue}>{totalStats.totalVisitors}</dd>
                            </div>
                        </div>
                    </div>

                    <div className={styles.statCard}>
                        <div className={styles.statContent}>
                            <div className={styles.statIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-up-right "><path d="M7 7h10v10"></path><path d="M7 17 17 7"></path></svg>
                            </div>
                            <div className={styles.statInfo}>
                                <dt className={styles.statLabel}>Total Referrals</dt>
                                <dd className={styles.statValue}>{totalStats.totalReferrals}</dd>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Search */}
                <div className={styles.searchContainer}>
                    <div className={styles.searchWrapper}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles.searchIcon}><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                        <input
                            type="text"
                            placeholder="Search by email or affiliate ID"
                            className={styles.searchInput}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>

                {/* Table */}
                <div className={styles.table}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead className={styles.tableHeader}>
                            <tr>
                                <th className={styles.tableHeaderCell}>Affiliate</th>
                                <th className={styles.tableHeaderCell}>Active Subs</th>
                                <th className={styles.tableHeaderCell}>Total Revenue</th>
                                <th className={styles.tableHeaderCell}>Referrals</th>
                                <th className={styles.tableHeaderCell}>Actions</th>
                            </tr>
                        </thead>
                        <tbody className={styles.tableBody}>
                            {filteredAffiliates.map((affiliate) => {
                                const totalRevenue = Object.values(affiliate.monthlyStats).reduce(
                                    (sum, month) => sum + (month.currentMonthMoneyMade || 0),
                                    0
                                );

                                return (
                                    <tr key={affiliate.affiliateId} className={styles.tableRow}>
                                        <td className={styles.tableCell}>
                                            <div className={styles.affiliateInfo}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail " style={{ marginRight: '0.5rem', color: 'var(--color-gray-400)' }}><rect width="20" height="16" x="2" y="4" rx="2"></rect><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path></svg>
                                                <div>
                                                    <div className={styles.affiliateEmail}>
                                                        {affiliate.userEmail === 'Email not found' ?
                                                            <span className={styles.noEmail}>No email</span> :
                                                            affiliate.userEmail}
                                                    </div>
                                                    <div className={styles.affiliateId}>{affiliate.affiliateId}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={styles.tableCell}>{affiliate.activeSubs}</td>
                                        <td className={styles.tableCell}>${totalRevenue.toFixed(2)}</td>
                                        <td className={styles.tableCell}>{affiliate.referredUsers.length}</td>
                                        <td className={styles.tableCell}>
                                            <button
                                                onClick={() => setSelectedAffiliate(affiliate)}
                                                className={styles.actionButton}
                                            >
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                {/* Modal */}
                {selectedAffiliate && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <div className={styles.modalHeader}>
                                <h2 className={styles.modalTitle}>Affiliate Details</h2>
                                <button
                                    onClick={() => setSelectedAffiliate(null)}
                                    className={styles.closeButton}
                                >
                                    ✕
                                </button>
                            </div>

                            <div className={styles.modalSection}>
                                <h3 className={styles.sectionTitle}>Basic Information</h3>
                                <dl className={styles.infoGrid}>
                                    <div>
                                        <dt className={styles.infoLabel}>Email</dt>
                                        <dd className={styles.infoValue}>{selectedAffiliate.userEmail}</dd>
                                    </div>
                                    <div>
                                        <dt className={styles.infoLabel}>Affiliate ID</dt>
                                        <dd className={styles.infoValue}>{selectedAffiliate.affiliateId}</dd>
                                    </div>
                                    <div>
                                        <dt className={styles.infoLabel}>Active Subscriptions</dt>
                                        <dd className={styles.infoValue}>{selectedAffiliate.activeSubs}</dd>
                                    </div>
                                </dl>
                            </div>

                            <div className={styles.modalSection}>
                                <h3 className={styles.sectionTitle}>Monthly Stats</h3>
                                <div className={styles.table}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <thead className={styles.tableHeader}>
                                            <tr>
                                                <th className={styles.tableHeaderCell}>Month</th>
                                                <th className={styles.tableHeaderCell}>Visitors</th>
                                                <th className={styles.tableHeaderCell}>Revenue</th>
                                                <th className={styles.tableHeaderCell}>Subscriptions</th>
                                            </tr>
                                        </thead>
                                        <tbody className={styles.tableBody}>
                                            {Object.entries(selectedAffiliate.monthlyStats).map(([month, stats]) => (
                                                <tr key={month} className={styles.tableRow}>
                                                    <td className={styles.tableCell}>{month}</td>
                                                    <td className={styles.tableCell}>{stats.currentMonthVisitors || 0}</td>
                                                    <td className={styles.tableCell}>${stats.currentMonthMoneyMade?.toFixed(2) || '0.00'}</td>
                                                    <td className={styles.tableCell}>{stats.currentMonthSubscriptions || 0}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className={styles.modalSection}>
                                <h3 className={styles.sectionTitle}>Referred Users</h3>
                                <ul className={styles.referredUsersList}>
                                    {selectedAffiliate.referredUsers.map((user, index) => (
                                        <li key={index} className={styles.referredUserItem}>
                                            <div className={styles.affiliateInfo}>
                                                <div>
                                                    <p className={styles.affiliateEmail}>
                                                        {user.email === 'Email not found' ?
                                                            <span className={styles.noEmail}>No email</span> :
                                                            user.email}
                                                    </p>
                                                    <p className={styles.affiliateId}>Event: {user.eventType}</p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
}