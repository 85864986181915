// useProRatedPrice.ts

import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../GlobalContextProvider';
import { AuthContext } from '../../AuthContextProvider';

/**
 * Pass `isEnabled = true` if you want to fetch the pro-rated price,
 * or `false` if you want to skip fetching.
 */
export function useProRatedPrice(isEnabled) {
    const { BACKEND_URL } = useContext(GlobalContext);
    const user = useContext(AuthContext);
    const [price, setPrice] = useState(null);

    useEffect(() => {
        if (!isEnabled) {
            // If disabled, reset or do nothing
            setPrice(null);
            return;
        }

        // Check local cache
        const cachedDataString = localStorage.getItem('proRatedPrice');
        if (cachedDataString) {
            const { cachedPrice, timestamp } = JSON.parse(cachedDataString);
            // If the cached data is still valid (within last 5 minutes), use it
            if (Date.now() - timestamp < 5 * 60 * 1000) {
                setPrice(cachedPrice);
                return;
            }
        }

        // Otherwise fetch from API
        axios
            .post(`${BACKEND_URL}/subscription/preview`, {
                userId: user?.uid
            })
            .then((res) => {
                const prorationAmount = res.data.proration_amount;
                setPrice(prorationAmount);
                // Update local cache
                localStorage.setItem(
                    'proRatedPrice',
                    JSON.stringify({
                        cachedPrice: prorationAmount,
                        timestamp: Date.now()
                    })
                );
            })
            .catch((err) => {
                console.error('Error fetching proration amount:', err);
            });
    }, [isEnabled, BACKEND_URL, user?.uid]);

    return price;
}
