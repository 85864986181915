import { useState, createContext } from "react"

export const GlobalContext = createContext()

export function GlobalContextProvider({ children }) {

    const [showRewards, setShowRewards] = useState(0)
    const BACKEND_URL = (
        !window.location.href.startsWith("http://localhost:3000/") ?
            "https://api.alphatwin.ai"
            :
            "http://127.0.0.1:7000"
    )

    return (
        <GlobalContext.Provider value={{ showRewards, setShowRewards, BACKEND_URL }}>
            {children}
        </GlobalContext.Provider>
    )
}