import React, { useState, useEffect, useContext } from 'react';
import styles from './RewardsModal.module.css';
import { AuthContext } from '../../AuthContextProvider';
import axios from 'axios';
import { getDoc } from 'firebase/firestore';
import { doc } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import { GlobalContext } from '../../GlobalContextProvider';

interface RewardsModalProps {
    isOpen: boolean;
    onClose: () => void;
    isPremiumUser: boolean;
}

interface SocialButtonState {
    loading: boolean;
    completed: boolean;
}

interface TaskState {
    completed: boolean;
    timestamp?: string;
}

interface ReviewState extends TaskState {
    rating?: number;
    feedback?: string;
}

export function RewardsModal({ isOpen, onClose, isPremiumUser }: RewardsModalProps) {
    const [instagramHandle, setInstagramHandle] = useState('');
    const [refCode, setRefCode] = useState(false)
    const [referralUsed, setReferralUsed] = useState(false);

    const [reviewState, setReviewState] = useState<ReviewState>({ completed: false });
    const user = useContext(AuthContext)

    // Review modal state
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [hoverRating, setHoverRating] = useState(0);
    const [selectedRating, setSelectedRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    useEffect(() => {

        const fetchReferralCode = async (referralCodeId) => {
            try {
                const docRef = doc(firestore, 'referral_codes', referralCodeId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // Document data is found
                    const refData = docSnap.data();
                    const { is_active } = refData
                    if (is_active) setReferralUsed(false)
                    else setReferralUsed(true)
                } else {
                    // No such document!
                    console.log('No matching document for that ID.');
                }
            } catch (err) {
                console.error('Error fetching referral code:', err);
            }
        };
        if (user && user.referralCode) {
            setRefCode(user.referralCode)
            fetchReferralCode(user.referralCode);
        }
    }, [user])

    useEffect(() => {
        if (user && user.storySubmitted) setStorySubmitted(user.storySubmitted)
        if (user && user.storyApproved) setStoryApproved(user.storyApproved)
        if (user && user.instagramHandle) setInstagramHandle(user.instagramHandle)
        if (user && user.reviewSubmitted) setReviewState({
            completed: true,
            timestamp: new Date().toISOString(),
            rating: selectedRating,
            feedback: feedback
        })

    }, [user])

    const [socialStates, setSocialStates] = useState<Record<string, SocialButtonState>>(
        user && user.allSocialsFollowed ?
            {
                instagram: { loading: false, completed: true },
                tiktok: { loading: false, completed: true },
                x: { loading: false, completed: true }
            } : {
                instagram: { loading: false, completed: false },
                tiktok: { loading: false, completed: false },
                x: { loading: false, completed: false }
            }
    );
    const [storySubmitted, setStorySubmitted] = useState(false);
    const [storyApproved, setStoryApproved] = useState(false);
    const { BACKEND_URL } = useContext(GlobalContext)

    useEffect(() => {
        const allSocialsFollowed = Object.values(socialStates).every(state => state.completed);

        if (allSocialsFollowed && user && !user.allSocialsFollowed) {
            axios.post(`${BACKEND_URL}/reward/socials`, {
                "user_id": user?.uid
            })
        }


    }, [user, socialStates])

    // useEffect(() => {
    //     const defaultSocialState: Record<string, SocialButtonState> = {
    //         instagram: { loading: false, completed: false },
    //         tiktok: { loading: false, completed: false },
    //         x: { loading: false, completed: false },
    //     };

    //     const savedSocials = localStorage.getItem('socialStates');
    //     if (savedSocials) {
    //         try {
    //             const parsed = JSON.parse(savedSocials);
    //             // Merge the parsed state with defaults
    //             const merged = {
    //                 ...defaultSocialState,
    //                 ...parsed,
    //             };
    //             setSocialStates(merged);
    //         } catch (err) {
    //             console.error('Error parsing socialStates:', err);
    //             setSocialStates(defaultSocialState);
    //         }
    //     } else {
    //         setSocialStates(defaultSocialState);
    //     }
    // }, []);

    const [copied, setCopied] = useState(false)

    if (!isOpen) return null;

    const handleSocialClick = async (
        platform: 'instagram' | 'tiktok' | 'x',
        url: string
    ) => {
        // Prevent re-click if already completed
        if (socialStates[platform].completed) return;

        // Open link in a new tab
        window.open(url, '_blank');

        // 1) Set loading: true
        setSocialStates(prev => ({
            ...prev,
            [platform]: { loading: true, completed: false },
        }));

        // 2) Simulate checking the follow action
        await new Promise(resolve => setTimeout(resolve, 5000));

        // 3) Set completed: true using the functional form
        setSocialStates(prev => {
            const newStates = {
                ...prev,
                [platform]: { loading: false, completed: true },
            };
            localStorage.setItem('socialStates', JSON.stringify(newStates));
            return newStates;
        });
    };


    const handleStorySubmit = async () => {
        if (!instagramHandle || storySubmitted) return;

        setStorySubmitted(true);
        // localStorage.setItem('storySubmitted', JSON.stringify(newState));
        // setInstagramHandle('');
        axios.post(`${BACKEND_URL}/reward/story`, {
            "user_id": user?.uid,
            "instagram_handle": instagramHandle
        })
    };

    const handleOverlayClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleReferralCopy = () => {
        navigator.clipboard.writeText(refCode);
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    };

    const handleReviewSubmit = () => {
        if (!selectedRating || !feedback || reviewState.completed) return;
        axios.post(`${BACKEND_URL}/reward/review`, {
            "user_id": user?.uid,
            "rating": selectedRating,
            "feedback": feedback
        })
            .then(() => {
                const newState = {
                    completed: true,
                    timestamp: new Date().toISOString(),
                    rating: selectedRating,
                    feedback: feedback
                };
                setReviewState(newState);
                setShowReviewModal(false);
                setSelectedRating(0);
                setFeedback('');
            })
            .catch(err => {
                console.error('Error submitting review:', err);
            });
    };

    const renderStars = (interactive = false) => {
        return Array.from({ length: 5 }, (_, index) => {
            const starNumber = index + 1;
            const filled = interactive ? starNumber <= (hoverRating || selectedRating) : starNumber <= (reviewState.rating || 0);

            return (
                <button
                    key={index}
                    className={`${styles.star} ${filled ? styles.starFilled : ''}`}
                    onMouseEnter={() => interactive && setHoverRating(starNumber)}
                    onMouseLeave={() => interactive && setHoverRating(0)}
                    onClick={() => interactive && setSelectedRating(starNumber)}
                    disabled={!interactive}
                    style={{ zIndex: reviewState.completed ? -10 : 1 }}
                >
                    {filled ?
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" style={{ width: "2rem", height: "2rem" }} className="w-8 h-8 iconify iconify--mdi" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.62L12 2L9.19 8.62L2 9.24l5.45 4.73L5.82 21z"></path></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" style={{ width: "2rem", height: "2rem" }} className="w-8 h-8 iconify iconify--mdi" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.39l-3.76 2.27l.99-4.28l-3.32-2.88l4.38-.37L12 6.09l1.71 4.04l4.38.37l-3.32 2.88l.99 4.28M22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.45 4.73L5.82 21L12 17.27L18.18 21l-1.64-7.03z"></path></svg>
                    }
                </button>
            );
        });
    };

    const allSocialsFollowed = Object.values(socialStates).every(state => state.completed);

    const iconHeight = 18

    const getSocialButton = (platform: 'instagram' | 'tiktok' | 'x') => {
        const configs = {
            instagram: {
                icon: <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height={`${iconHeight}px`} width={`${iconHeight}px`} xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>,
                buttonClass: styles.instagramButton,
                url: 'https://www.instagram.com/reel/DEIlkAyRjnE/'
            },
            tiktok: {
                icon: <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height={`${iconHeight}px`} width={`${iconHeight}px`} xmlns="http://www.w3.org/2000/svg"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path></svg>,
                buttonClass: styles.tiktokButton,
                url: 'https://www.tiktok.com/@alphatwin.ai/video/7453737264615640363'
            },
            x: {
                icon: <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height={`${iconHeight}px`} width={`${iconHeight}px`} xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>,
                buttonClass: styles.xButton,
                url: 'https://x.com/alphatwinai'
            }
        };

        const config = configs[platform];
        const state = socialStates[platform];

        return (
            <button
                onClick={() => handleSocialClick(platform, config.url)}
                disabled={state.completed}
                className={`${styles.button} ${state.completed ? styles.completedButton : config.buttonClass}`}
            >
                {state.loading ? (
                    <div className={styles.loadingSpinner} />
                ) : state.completed ? (
                    // <Icon icon="mdi:check" className="w-5 h-5" />
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height={iconHeight} width={iconHeight} xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                ) : (
                    config.icon
                )}
                {platform === 'x' ? '' : platform.charAt(0).toUpperCase() + platform.slice(1)}
            </button>
        );
    };

    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h2 className={styles.title}>Unlock Rewards  🎁</h2>
                    <button onClick={onClose} className={styles.closeButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-x "><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>
                    </button>
                </div>

                <div className={styles.section}>
                    {!isPremiumUser && (
                        <div className={styles.card}>
                            {allSocialsFollowed && (
                                <div className={styles.completedOverlay}>
                                    <p className={styles.completedText}>✨ All Platforms Followed!</p>
                                    <p className={styles.completedSubtext}>2 messages have been added to your account.</p>
                                </div>
                            )}
                            <h3 className={styles.cardTitle}>Replenish Your Messages</h3>
                            <p className={styles.cardDescription}>Follow us social media to unlock more messages!</p>
                            <div className={styles.socialButtons}>
                                {getSocialButton('instagram')}
                                {getSocialButton('tiktok')}
                                {getSocialButton('x')}
                            </div>
                        </div>
                    )}

                    <div className={styles.card}>
                        {reviewState.completed && (
                            <div className={styles.completedOverlay}>
                                <p className={styles.completedText}>✨ Review Approved!</p>
                                <p className={styles.completedSubtext}>Your guide has been sent to your email</p>
                            </div>
                        )}
                        <h3 className={styles.cardTitle}>Ultimate Guide to Financial Freedom</h3>
                        <p className={styles.cardDescription}>Share your experience with AlphaTwin</p>
                        <div className={styles.reviewContainer}>
                            <div className={styles.stars}>{renderStars(!reviewState.completed)}</div>
                            {!reviewState.completed && (
                                <button
                                    className={styles.submitButton}
                                    onClick={() => setShowReviewModal(true)}
                                >
                                    Write a Review
                                </button>
                            )}
                        </div>
                    </div>

                    {isPremiumUser && (
                        <div className={styles.card}>
                            {referralUsed && (
                                <div className={styles.completedOverlay}>
                                    <p className={styles.completedText}>✨ Discount Applied!</p>
                                    <p className={styles.completedSubtext}>Your referral code has been applied. Both you and your friend got 50% off your monthly subscription!</p>
                                </div>
                            )}
                            <h3 className={styles.cardTitle}>Claim 50% Off Next Month</h3>
                            <p className={styles.cardDescription}>Refer a friend and you both get 50% off AlphaTwin!</p>
                            {!refCode ?
                                <button className={styles.submitButton} style={{
                                    width: "100%", color: 'white', textAlign: "center",
                                    display: "flex", alignItems: "center", justifyContent: "center", gap: ".5em"
                                }}
                                    onClick={() => axios.post(`${BACKEND_URL}/generate_referral_code`, {
                                        userId: user?.uid,
                                        // AQS1
                                    })
                                        .then(a => console.log(a))
                                        .catch(e => console.log(e))
                                    }>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height={`${iconHeight}px`} width={`${iconHeight}px`} xmlns="http://www.w3.org/2000/svg"><path d="M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path></svg>
                                    Generate Promo Code for Friends
                                </button>
                                :
                                <div className={styles.referralCode}>
                                    <code className={styles.code}>
                                        {refCode}
                                    </code>
                                    <button
                                        className={styles.copyButton}
                                        onClick={handleReferralCopy}
                                        disabled={referralUsed}
                                    >
                                        {copied ?
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height={iconHeight - 3} width={iconHeight - 3} xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                                            :
                                            "Copy"
                                        }
                                    </button>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
            {showReviewModal && (
                <div className={styles.reviewModalOverlay} onClick={() => setShowReviewModal(false)}>
                    <div className={styles.reviewModalContent} onClick={e => e.stopPropagation()}>
                        <div className={styles.reviewModalHeader}>
                            <h3 className={styles.reviewModalTitle}>Write a Review</h3>
                            <button onClick={() => setShowReviewModal(false)} className={styles.closeButton}>
                                {/* <X size={20} /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x "><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>
                            </button>
                        </div>
                        <div className={styles.reviewModalBody}>
                            <div className={styles.stars}>{renderStars(true)}</div>
                            <textarea
                                placeholder="Share your honest feedback about AlphaTwin..."
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                className={styles.reviewTextarea}
                                maxLength={400}
                            />
                            <button
                                className={`${styles.submitButton} ${(!selectedRating || !feedback) ? styles.submitButtonDisabled : ''}`}
                                onClick={handleReviewSubmit}
                                disabled={!selectedRating || !feedback}
                            >
                                Submit Review
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}