import React from 'react';
import styles from '../../styles/LinkDetails.module.css';

export function LinkDetails({ affiliateCode, aff }) {
    const affLink = `${window.location.origin}/?ref=${affiliateCode}`;
    return (
        <div className={styles.container} style={{ fontFamily: "Open Sans" }}>
            <div className={styles.header}>
                {/* <Link className={styles.icon} size={20} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles.icon}><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                <h3 className={styles.title}>Your Affiliate Link</h3>
            </div>
            <div className={styles.content}>
                <div className={styles.linkBox}>
                    <input
                        type="text"
                        value={affLink}
                        readOnly
                        style={{ fontFamily: "Open Sans" }}
                        className={styles.linkInput}
                    />
                    <button className={styles.copyButton} onClick={() => navigator.clipboard.writeText(affLink)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles.icon}><rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect><path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path></svg>
                    </button>
                    <a className={styles.visitButton} href={affLink} target="_blank" rel='noreferrer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles.icon}><path d="M15 3h6v6"></path><path d="M10 14 21 3"></path><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path></svg>
                    </a>
                </div>
                <div className={styles.stats}>
                    <div className={styles.stat}>
                        <span className={styles.statLabel}>Total Unique Visits</span>
                        <span className={styles.statValue}>{aff?.currentMonthUniqueVisits ? aff?.currentMonthUniqueVisits : 0}</span>
                    </div>
                    <div className={styles.stat}>
                        <span className={styles.statLabel}>Conversion Rate</span>
                        <span className={styles.statValue}>{
                            aff?.currentMonthSubscriptions ?
                                <>{Math.round(aff.currentMonthSubscriptions / aff.currentMonthUniqueVisits * 10000) / 100}%</>
                                :
                                0
                        }
                        </span>
                    </div>
                    <div className={styles.stat}>
                        <span className={styles.statLabel}>Total Checkout Sessions</span>
                        <span className={styles.statValue}>{aff?.currentMonthCheckoutSessions ? aff.currentMonthCheckoutSessions : 0}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}