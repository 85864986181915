import React, { useContext } from 'react';
import '../../styles/SubscriptionDetails.css';
import CheckoutButton from '../Chat/CheckoutButton';
import { AuthContext } from '../../AuthContextProvider';
import data from '../../data.json'
import axios from 'axios';
import { GlobalContext } from '../../GlobalContextProvider';

// const formatDate = (date: Date): string => {
//     return new Intl.DateTimeFormat('en-US', {
//         year: 'numeric',
//         month: 'long',
//         day: 'numeric',
//     }).format(date);
// };

const { monthlyTestPriceID, yearlyTestPriceID, monthlyPriceID, yearlyPriceID } = data

const formatDate = timestamp => {

    // Multiply by 1000 to convert seconds to milliseconds
    const date = new Date(timestamp * 1000);

    // Format the date string
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

interface SubscriptionDetailsProps {
    testMode: boolean;
    renewalDate: Date;
    isAnnual: boolean;
    price: number;
    onUnsubscribe: () => void;
}


const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
    testMode,
    renewalDate,
    isAnnual,
    price,
    onUnsubscribe,
    promoCode
}) => {
    const user = useContext(AuthContext)
    const { BACKEND_URL } = useContext(GlobalContext)

    const handleResume = async (feedback) => {

        console.log(2)
        axios.post(`${BACKEND_URL}/uncancel_subscription`, {
            "userId": user?.uid
        })
            .then(res => console.log(res))
            .catch(err => console.error(err))
    };
    return (
        <div className="subscription-details">
            <div className="renewal-info">
                <p className="renewal-date">
                    {
                        user?.cancelAtPeriodEnd ?
                            "Expires" :
                            "Next renewal"
                    } on {formatDate(user?.currentPeriodEnd)}
                </p>
                {/* <p className="billing-info">
                    {userData.is_annual
                        ? `Billed annually at $${(89.99).toFixed(2)}`
                        : `Billed monthly at $${(14.99).toFixed(2)}`}
                </p> */}
            </div>
            {
                !user?.cancelAtPeriodEnd ?
                    <button
                        onClick={onUnsubscribe}
                        className="unsubscribe-button"
                    >
                        Cancel Subscription
                    </button>
                    :
                    <button className='subscribe-button primary' onClick={handleResume}> Resume Subscription </button>
            }
        </div>
    );
};

export default SubscriptionDetails;