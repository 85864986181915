import React, { useContext, useEffect, useState } from 'react';
import SubscriptionDetails from './SubscriptionDetails.tsx';
import { PricingPlan } from '../types/subscription.ts';
import axios from 'axios';
import { AuthContext } from '../../AuthContextProvider.js';
import UnsubscribeFeedbackModal from '../global/UnsubscribeFeedbackModal.jsx';
import CheckoutButton from '../Chat/CheckoutButton.jsx';
import data from '../../data.json'
import PromoCodeSection from './PromoModal.tsx';
import { GlobalContext } from '../../GlobalContextProvider.js';
import { useProRatedPrice } from './useProRatedPrice.js';
import styles from '../Chat/RewardsModal.module.css'

interface PricingCardProps {
    plan: PricingPlan;
    testMode: boolean;
    billingPeriod: 'month' | 'year';
    onSubscribe: () => void;
}

const formatDate = timestamp => {

    // Multiply by 1000 to convert seconds to milliseconds
    const date = new Date(timestamp * 1000);

    // Format the date string
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

function isDowngrade(p1, p2) {
    if (p1 == "annual_basic" && p2 == "monthly_basic") return true;
    if (p1 == "annual_premium" && p2 == "monthly_premium") return true;
    if (p1 == "annual_premium" && p2 == "monthly_basic") return true;
    if (p1 == "annual_premium" && p2 == "annual_basic") return true;
    if (p1 == "monthly_premium" && p2 == "annual_basic") return true;
    if (p1 == "monthly_premium" && p2 == "monthly_basic") return true;
    return false;
}

var { monthlyPriceID, yearlyPriceID, monthlyTestPriceID, yearlyTestPriceID,
    monthlyUltraPriceID, monthlyUltraTestPriceID, yearlyUltraPriceID, yearlyUltraTestPriceID
} = data

function get_price_id(plan, testMode, billingPeriod) {
    console.log(plan)
    if (testMode) {
        if (billingPeriod === 'year') {

            if (plan.name == "Premium") {
                return [yearlyTestPriceID, "annual_basic"]
            }

            if (plan.name == "Ultra-Premium") {
                return [yearlyUltraTestPriceID, "annual_premium"]
            }

        }
        else {
            if (plan.name == "Premium") {
                return [monthlyTestPriceID, "monthly_basic"]
            }

            if (plan.name == "Ultra-Premium") {
                return [monthlyUltraTestPriceID, "monthly_premium"]
            }
        }
    } else {
        console.log(1)
        if (billingPeriod === 'year') {
            console.log(2)
            if (plan.name == "Premium") {
                return [yearlyPriceID, "annual_basic"]
            }

            if (plan.name == "Ultra-Premium") {
                return [yearlyUltraPriceID, "annual_premium"]
            }

        }
        else {
            console.log(2)
            if (plan.name == "Premium") {
                return [monthlyPriceID, "monthly_basic"]
            }

            if (plan.name == "Ultra-Premium") {
                return [monthlyUltraPriceID, "monthly_premium"]
            }
        }
    }
}

const findButton = (plan, billingPeriod, testMode, promoCode) => {
    if (plan.name === "Premium" && plan.isCurrentPlan) return <></>
    if (plan.name === "Premium" && !plan.isCurrentPlan) return <CheckoutButton alt={2} testMode={testMode} priceId={
        billingPeriod === 'year' ?
            // 'price_1QXeZlKwYi8l6iBvku4w6hNe'
            (testMode ? yearlyTestPriceID : yearlyPriceID)
            :
            // 'price_1PxbK0KwYi8l6iBvrAFic2Oc'
            (testMode ? monthlyTestPriceID : monthlyPriceID)
    } promoCode={promoCode} />

    if (plan.name === "Free" && plan.isCurrentPlan) return <button
        className={`subscribe-button secondary`}
        disabled={true}
    >
        Current Plan
    </button>
    if (plan.name === "Free" && !plan.isCurrentPlan) return <></>

    if (plan.name === "Ultra-Premium" && plan.isCurrentPlan) return <></>

    if (plan.name === "Ultra-Premium" && !plan.isCurrentPlan) return <CheckoutButton alt={2} testMode={testMode} priceId={
        billingPeriod === 'year' ?
            // 'price_1QXeZlKwYi8l6iBvku4w6hNe'
            (testMode ? yearlyUltraTestPriceID : yearlyUltraPriceID)
            :
            // 'price_1PxbK0KwYi8l6iBvrAFic2Oc'
            (testMode ? monthlyUltraTestPriceID : monthlyUltraPriceID)
    } promoCode={promoCode} />

}

const PricingCard: React.FC<PricingCardProps> = ({
    plan,
    testMode,
    billingPeriod,
    onSubscribe,
    promoCode,
    setShowModal,
    submitted,
    userPlan
}) => {
    const user = useContext(AuthContext);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const handleUnsubscribeClick = () => {
        setShowFeedbackModal(true);
    };
    const { BACKEND_URL } = useContext(GlobalContext)
    const [upgrading, setUpgrading] = useState(false)
    const handleUnsubscribeFeedback = async (feedback) => {
        try {
            // Here you can send the feedback to your backend/database
            // Proceed with actual unsubscribe action
            console.log(2)
            const res = await axios.post(`${BACKEND_URL}/cancel_subscription`, {
                "userId": user.uid,
                "reason": feedback,
                'email': user?.email
            })
            console.log(res)

        } catch (error) {
            console.error(error)
        }
    };

    let match
    if (user && user.subscriptionStatus == "Premium") match = (
        (user.is_annual && billingPeriod == 'year') ||
        (!user.is_annual && billingPeriod == 'month')
    )
    else match = true

    const x = get_price_id(plan, testMode, billingPeriod)
    if (x) {
        var price_id = x[0]
        var new_plan_type = x[1]
    }

    const downgrade = isDowngrade(userPlan, new_plan_type)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    function handleUpgradeClick() {
        setShowUpgradeModal(true)
    }

    const handleUpgrade = () => {
        setUpgrading(true)
        axios.post(`${BACKEND_URL}/upgrade_subscription`, {
            userId: user?.uid,
            new_price_id: price_id,
            new_plan_type: new_plan_type
        })
            .then(res => {
                setUpgrading(false)
                console.log(res)
            })
            .catch(e => {
                setUpgrading(false)
                console.error(e)
            })
    }

    const isProrated = userPlan === "annual_basic" && new_plan_type === "annual_premium"
    var price = useProRatedPrice(isProrated)

    var annualPrice = (new_plan_type == 'annual_premium' ? plan.price * 12 : plan.price * 12 - 0.01).toFixed(2)

    return (
        <>
            <div className={`pricing-card ${plan.isPremium && plan && !plan.premiumLevel ? 'premium' : ''}`}>
                {plan.isCurrentPlan && match && <div className="current-plan">Current Plan</div>}
                <h2 className="plan-name">{
                    plan.name === 'Free' ? plan.name : (
                        plan.name === 'Premium' ? "Basic" : "Premium"
                    )
                }</h2>
                {plan.name === "Premium" && !submitted &&
                    <button className='haveCode' onClick={() => {
                        setShowModal(true)
                    }}>Have a promo code?</button>
                }

                <div className="price-container">
                    {plan.originalPrice && (
                        <span className="original-price">${plan.originalPrice.toFixed(2)}</span>
                    )}
                    {/* {console.log("O", userPlan === "annual_basic", new_plan_type === "annual_premium")} */}
                    <span className="price">${
                        (isProrated) ?
                            // <ProRated />
                            <>{
                                !price ?
                                    <div className={styles.loadingSpinner} style={{
                                        display: "inline-block",
                                        border: "3px solid var(--purple)",
                                        borderTopColor: "transparent",
                                        width: ".7em",
                                        height: ".7em",
                                        marginInline: '.25em'
                                    }}></div>
                                    :
                                    (price / 12).toFixed(2)
                            }</>
                            :
                            plan.price.toFixed(2)
                    }</span>
                    <span className="period">/month</span>
                    {isProrated && <p className="annual-savings">
                        {/* This is a pro-rated price, based on your remaining billing period! */}
                        {/* <>Pay <b>${price}</b> right now, lasting till {formatDate(user?.currentPeriodEnd)}, and you will be billed $234 annually thereafter.</> */}
                    </p>}
                </div>
                <div className="features">
                    {plan.features.map((feature, index) => (
                        <div key={index} className="feature">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-check "><path d="M20 6 9 17l-5-5"></path></svg>
                            <span>{feature}</span>
                        </div>
                    ))}
                </div>
                {plan.isCurrentPlan && plan.subscription && (
                    (user && user.is_annual && billingPeriod === 'year') ||
                    (user && !user.is_annual && billingPeriod === 'month')) ? (
                    <SubscriptionDetails
                        testMode={testMode}
                        renewalDate={plan.subscription.renewalDate}
                        isAnnual={plan.subscription.isAnnual}
                        price={plan.subscription.price}
                        onUnsubscribe={handleUnsubscribeClick}
                        promoCode={promoCode}
                    />
                ) : (
                    downgrade ? <></> :
                        (user?.subscriptionStatus === "Premium" && plan.name !== "Free" ?
                            <button
                                disabled={upgrading}
                                className='subscribe-button primary' onClick={handleUpgradeClick} style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: ".3em" }}>
                                {!upgrading ? <>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M376.2 224H268l52.4-186.9c.9-4.5-4.6-7.1-7.2-3.4L129.5 274.6c-3.8 5.6-.2 13.4 6.3 13.4H244l-52.4 186.9c-.9 4.5 4.6 7.1 7.2 3.4l183.7-240.8c3.7-5.7.2-13.5-6.3-13.5z"></path></svg>
                                    Upgrade
                                </> : <>Upgrading...</>
                                }
                            </button>
                            :
                            findButton(plan, billingPeriod, testMode, promoCode))
                )}
                {plan.isPremium && billingPeriod === 'year' && (
                    <p className="annual-savings">{isProrated ?
                        // <></>
                        <>Pay <b>${price}</b> right now, lasting till {formatDate(user?.currentPeriodEnd)}, and you will be billed $234 annually thereafter.</>

                        // <>Pay a pro-rated amount of ${price} right now, and you will be billed $234 thereafter.</>
                        :
                        <>Billed annually at ${
                            isProrated ? price : annualPrice}</>}</p>
                )}
                {/* <button onClick={() => {
                    axios.post(`${BACKEND_URL}/upgrade_subscription`, {
                        userId: user?.uid,
                        new_price_id: yearlyUltraTestPriceID,
                    })
                        .then(res => console.log(res))
                        .catch(e => console.error(e))
                }}>WeDo</button> */}
            </div>
            <UnsubscribeFeedbackModal
                isOpen={showFeedbackModal}
                onClose={() => setShowFeedbackModal(false)}
                onSubmit={handleUnsubscribeFeedback}
            />
            {showUpgradeModal && (
                <div className="modal-overlay">
                    <div className="pmodal upgrade-modal">
                        <button
                            className="modal-close"
                            onClick={() => setShowUpgradeModal(false)}
                            aria-label="Close modal"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className='modal-close'><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg>
                        </button>
                        <h2 style={{
                            fontSize: "18px"
                        }}>Confirm Upgrade to {plan.name}</h2>
                        <div className="upgrade-details">
                            <p>By confirming, your subscription will be upgraded immediately:</p>
                            <ul>
                                <li>You will be charged ${isProrated ?
                                    price
                                    :
                                    billingPeriod === 'year' ?
                                        annualPrice
                                        :
                                        plan.price.toFixed(2)
                                } {
                                        isProrated
                                            ? '(pro-rated amount)' : ''} today</li>
                                <li>The same payment method will be used</li>
                                <li>Your new benefits will be available instantly</li>
                                {isProrated &&
                                    <li>
                                        <>Pay <b>${price}</b> right now, lasting till {formatDate(user?.currentPeriodEnd)}, and you will be billed $234 annually thereafter.</>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="pmodal-buttons">
                            {/* <button
                                className="pmodal-button secondary"
                                onClick={() => setShowUpgradeModal(false)}
                                style={{fontSize: "17px"}}
                            >
                                Cancel
                            </button> */}
                            <button
                                className="pmodal-button primary"
                                onClick={() => {
                                    handleUpgrade()
                                    setShowUpgradeModal(false)
                                }}
                                style={{ fontSize: "17px", paddingBlock: "13px", display: "flex", alignItems: "center", justifyContent: "center", gap: "3px" }}
                            >
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="19px" width="19px" xmlns="http://www.w3.org/2000/svg"><path d="M376.2 224H268l52.4-186.9c.9-4.5-4.6-7.1-7.2-3.4L129.5 274.6c-3.8 5.6-.2 13.4 6.3 13.4H244l-52.4 186.9c-.9 4.5 4.6 7.1 7.2 3.4l183.7-240.8c3.7-5.7.2-13.5-6.3-13.5z"></path></svg>
                                Confirm Upgrade
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PricingCard;