import React, { useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { getDeviceId } from '../../utils/deviceId';
// import { getEmailId } from '../../utils/email';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import History from './History';

import Header from '../global/Header';
import { AuthContext } from '../../AuthContextProvider';
import RightBar from './RightBar';
import TypingIndicator from './TypingIndicator';
import Models from './Models';
import Input from './Input';
import { getCurrentTime, getMessages } from '../../utils/helpers';
import MiddleTop from './MiddleTop';
import ChatLog from './ChatLog';
import data from '../../data.json'
import BrowserModal from '../global/BrowserModal';
import Resizer from 'react-image-file-resizer';

import { auth, firestore } from '../../firebaseConfig'; // Adjust the import path to your Firebase config
import {
  collection,
  query,
  orderBy,
  getDocs
} from 'firebase/firestore';
import DraggingIndicator from './DraggingIndicator';
import { GlobalContext } from '../../GlobalContextProvider';

var lastInp;
const { messageLimit, premiumMessageLimit } = data

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// -- ADD THIS ENTIRE FUNCTION INSIDE `Chat.jsx`, near your other helper functions --

/**
 * fetchDataStreaming sends a POST to /api/main_stream and consumes the response as a stream (SSE).
 * 
 *  - We build a single new "assistant" message with user=0 in your `messages` state,
 *    then continuously update its `content` as tokens arrive.
 *  - We finish when the server signals the end of the response stream.
 */
async function fetchDataStreaming(msg, sessionID, user, deviceId, influencerID, newID, onToken, onComplete, onError, BACKEND_URL,
  setImageFile, setImgLoading, imageFile
) {
  try {
    if (imageFile) {
      var b64 = await fileToBase64(imageFile)
    }

    setImageFile(null)
    console.log("Polytetrafluerothelene")
    setImgLoading(true)
    setTimeout(() => {
      setImgLoading(false)
    }, 3000);

    const response = await fetch(BACKEND_URL + "/api/main_stream", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        body: {
          UUID: user?.uid,
          influencer_id: influencerID,
          new_id: newID,
          session_id: sessionID,
          question: msg.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit),
          device_id: deviceId,
          email: user?.email ? user.email : null,
          "imageFile": b64 ? b64 : "none"
        }
      })
    });

    if (!response.ok) {
      throw new Error(`Stream request failed with status ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let buffer = ""; // store partial chunks until we see "\n\n"

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      if (readerDone) {
        done = true;
        break;
      }
      // Decode the chunk into text
      const chunk = decoder.decode(value, { stream: true });
      console.log("value: ", value, "chunk: ", JSON.stringify(chunk), "buffer: ", buffer)
      buffer += chunk;

      // SSE typically sends "data: partial_text\n\n" per event
      // We'll split on the double newline that signals the end of each SSE "event"
      const parts = buffer.split("\n\n");
      console.log('parts: ', parts)
      // Everything but the last entry are complete SSE events
      var counter = 0;
      for (let i = 0; i < parts.length; i++) {
        const sseEvent = parts[i];
        console.log("SSE: ", sseEvent)
        if (sseEvent.length === 0) {
          counter += 1
        } else {
          counter = 0
        }

        // Example: "data: Hello this is partial text"
        if (sseEvent.startsWith("data: ")) {
          // Extract the partial text after "data: "
          const partialText = sseEvent.slice("data: ".length);
          // Fire your callback to append this partial text to the UI
          onToken(partialText);
        }

        else if (counter === 1 && sseEvent.length === 0 && i < parts.length - 1 && parts[i + 1].startsWith("data: ")) {
          onToken("\n\n")
        }

        else if (counter === 2) {
          console.log("Baboon")
          onToken("\n\n")
          counter = 0
        }

        else if (sseEvent === "\n") {
          console.log("Zebra")
          onToken("\n")
        }
      }

      // The last part might be an incomplete event, keep in buffer
      buffer = parts[parts.length - 1];
      if (buffer === '\n') buffer = ''
    }

    // If we exit the loop, the stream ended
    onComplete();

  } catch (err) {
    onError(err);
  }
}


export default function App() {

  const [messages, setMessages] = useState([
    // { content: "HI!", user: 0 }
  ])

  const [inp, setInp] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [testMode, setTestMode] = useState(
    window.location.href.startsWith("http://localhost") || window.location.href.includes("192.168.") ?
      true
      :
      false
  )
  const [streamMode, setStreamMode] = useState(false)
  // const [email, setEmail] = useState("no-email")
  const [deviceId, setDeviceId] = useState(null)
  const [closed, setClosed] = useState(false)
  const [shown, setShown] = useState(window.innerWidth <= 764 ? 1 : 2)
  const [sessionID, setSessionID] = useState(uuidv4())
  const user = useContext(AuthContext)
  const [newID, setNewID] = useState(true)
  const [influencerID, setInfluencerID] = useState(
    "aiguy"
  )
  const [err, setErr] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showBrowserModal, setShowBrowserModal] = useState(false);

  useEffect(() => {
    if (!user) handlePen()
  }, [user])

  useEffect(() => {
    // console.log(sessionID)

  }, [sessionID])

  const inpRef = useRef()
  const recRef = useRef()
  const sendRef = useRef()
  const lastMessageRef = useRef(null);
  const prevMessagesRef = useRef([])
  const prevSessionID = useRef(uuidv4())

  useEffect(() => {
    // setReadMore(false)
    const temp = messages
    setMessages(prevMessagesRef.current)
    prevMessagesRef.current = [...temp]

    const t = sessionID
    const y = sessionID
    setSessionID(prevSessionID.current)
    prevSessionID.current = t

    // const x = uuidv4()
    // setSessionID(x)
    setNewID(true)
    lastMessageRef.current = y
  }, [influencerID])

  useEffect(() => {
    setDeviceId(getDeviceId())
    // setEmail(getEmailId())
  }, [])

  useEffect(() => {
    if (messages.length) {

      const lastMessage = messages[messages.length - 1]

      if (lastMessage.user === 0 && lastMessageRef.current === sessionID && !streamMode) {
        // console.log(lastMessageRef.current, sessionID)
        recRef.current.play();
      }

      lastMessageRef.current = sessionID;

      // Reset input if last message is from the user (user === 1)
      if (lastMessage.user === 1) {
        setInp("");
      }
    }
  }, [messages])
  console.log(messages)
  useEffect(() => {

    // if (!disabled) {
    animateScroll.scrollToBottom({
      containerId: "chatContainer",
      duration: 300,
      delay: 0,
      smooth: "easeIn",
    });
    // }

  }, [messages]);

  const { BACKEND_URL } = useContext(GlobalContext)

  async function fetchData(msg) {
    if (testMode) return new Promise((resolve) => setTimeout(resolve, 4000, {
      data: {
        'output_list': getMessages(msg)
      }
    }))

    else {

      if (imageFile) {
        var b64 = await fileToBase64(imageFile)
      }
      // console.log(b64)

      return axios.post(`${BACKEND_URL}/api/main`, {
        body: {
          'UUID': user?.uid,
          'influencer_id': influencerID,
          'new_id': newID,

          "session_id": sessionID,
          "question": msg.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit),
          "device_id": deviceId,
          "email": user?.email ? user.email : null,
          "imageFile": imageFile ? b64 : "none"
        }
      })
    }
  }

  useEffect(() => {
    // if (messages.length && messages[messages.length - 1]?.user === 0 && disabled === sessionID) setDisabled(false)
  }, [messages])

  // const p = useRef()
  const latestSessionIDRef = useRef(sessionID);

  useEffect(() => {
    latestSessionIDRef.current = sessionID;
  }, [sessionID]);


  const handleSubmit = (retry = false) => {
    setErr(false);
    if (disabled || (!retry && inp.trim().length === 0)) return;
    if (inp.length) lastInp = inp;

    const userInput = retry
      ? lastInp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit)
      : inp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit);

    // 1) Create the user message
    const newMessage = {
      content: userInput,
      user: 1,
      id: Date.now(),
      time: getCurrentTime(),
      showTime: true,
      imgRef: (imageFile ? URL.createObjectURL(imageFile) : null)
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInp("");
    setDisabled(sessionID);

    if (!streamMode) {
      const s = sessionID

      // try {
      fetchData(retry ? lastInp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit) : inp.slice(0, user.subscriptionStatus === "Premium" ? premiumMessageLimit : messageLimit))
        .then(response => {
          if (response.data && response.data["output_list"]) {
            sendMessagesSequentially(response.data["output_list"], s)
            // .then(() => {
            // recRef.current.play()
            setDisabled(false);
            setNewID(false);
            setTimeout(() => {
              inpRef.current && inpRef.current.focus();
            }, 100);
            // })
          }
        })
        .catch(err => {
          if (err && err.response && err.response.data && err.response.data.message) setErr(err.response.data.message);
          else setErr(err.message)
          console.error(err)
          setDisabled(false)
        })

      setImageFile(null)
      setImgLoading(true)
      setTimeout(() => {
        setImgLoading(false)
      }, 3000);
      // } catch (error) {
      //   console.error(error);
      // } finally {

      // }
    }

    else {
      // 2) Create an "assistant" message right away, empty content
      //    We'll fill it incrementally with partial tokens.
      const streamingMsgId = Date.now() + 1000; // any unique ID
      setMessages(prev => [
        ...prev,
        {
          user: 0,
          content: "",
          id: streamingMsgId,
          time: getCurrentTime(),
          showTime: true
        }
      ]);

      // 3) Call the fetchDataStreaming function
      fetchDataStreaming(
        userInput,        // msg
        sessionID,
        user,
        deviceId,
        influencerID,
        newID,
        // onToken callback: fired every time a partial chunk arrives
        (partial) => {
          // Append partial text to the last assistant message
          setMessages(prev => {
            // find that assistant msg
            const index = prev.findIndex(m => m.id === streamingMsgId);
            if (index === -1) return prev; // not found, shouldn't happen
            const updated = [...prev];
            console.log("Partial: ", JSON.stringify(partial), partial === "\n", partial.includes("\n"))
            updated[index] = {
              ...updated[index],
              content: updated[index].content + partial
            };
            return updated;
          });
        },
        // onComplete
        () => {
          // Stream finished
          setDisabled(false);
          setNewID(false);
          setTimeout(() => {
            inpRef.current && inpRef.current.focus();
          }, 100);
          // optional beep at the end:
          recRef.current.play();
        },
        // onError
        (error) => {
          console.error("Streaming error:", error);
          setErr(error.message);
          setDisabled(false);
        },
        BACKEND_URL,
        setImageFile,
        setImgLoading,
        imageFile
      );
    }
  };

  const sendMessagesSequentially = async (messagesArray, s) => {
    if (s !== latestSessionIDRef.current) return;
    for (let i = 0; i < messagesArray.length; i++) {
      try {

        // await new Promise((resolve) => setTimeout(resolve, 1000));
        const newMessage = {
          user: 0,
          content: messagesArray[i].content ? messagesArray[i].content : messagesArray[i],
          id: messagesArray[i].id ? messagesArray[i].id : Date.now() + i,
          time: getCurrentTime(), showTime: i === messagesArray.length - 1
        };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        recRef.current.play();
      } catch (e) {
        console.err(e)
      }
    }
  }

  useEffect(() => {
    if (window.innerWidth < 1300) setClosed(true)
  }, [])

  const handleHistClick = (c) => {
    setSessionID(c)
    setNewID(false)
    setShown(1)
  }

  useEffect(() => {
    setErr(false)
  }, [sessionID])

  const handlePen = () => {
    setNewID(true)
    const x = uuidv4()
    setSessionID(x)
    setMessages([])
    lastMessageRef.current = x
  }

  const [isWindows, setIsWindows] = useState(false);

  // function isTelegramWebApp() {
  //   // @ts-ignore
  //   return typeof TelegramWebviewProxy !== 'undefined';
  // }

  useEffect(() => {
    const platform = navigator.platform.toLowerCase();
    if (platform.indexOf('win') > -1) {
      setIsWindows(true);
    }

    // const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for common in-app browsers
    // const inAppBrowserRegex = /(Instagram|FBAN|FBAV|Twitter|Telegram)/i;

    // if (inAppBrowserRegex.test(userAgent) || isTelegramWebApp()) {
    //   setShowBrowserModal(true);
    // }
  }, []);

  useEffect(() => {
    if (!sessionID || !user || !user?.uid) {
      setMessages([]);
      return;
    }

    const fetchMessages = async () => {
      const messagesRef = collection(firestore, `chatSessions/${sessionID}/messages`);
      const q = query(messagesRef, orderBy('timestamp'));
      try {
        const querySnapshot = await getDocs(q);
        const fetchedMessages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMessages(fetchedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
        setMessages([]);
      }
    };

    fetchMessages();

    // const messagesRef = collection(firestore, `chatSessions/${sessionID}/messages`);
    // const q = query(messagesRef, orderBy('timestamp'));

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   const firestoreMessages = querySnapshot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));

    //   setMessages(firestoreMessages);
    // }, (error) => {
    //   console.error('Error fetching messages:', error);
    // });

    // return () => unsubscribe();
  }, [sessionID, auth.currentUser?.uid]);

  const [isDragging, setIsDragging] = useState(false);
  const [dragCount, setDragCount] = useState(0);
  const [showImg, setShowImg] = useState(user?.subscriptionStatus === "Premium")

  useEffect(() => {
    if (user) {
      const isPremiumUser = (user.subscriptionStatus === "Premium" && user.premiumLevel && user.premiumLevel == '1')
      setShowImg(isPremiumUser)
      setStreamMode(isPremiumUser)
    }
    else setShowImg(false)
  }, [user])

  const handleDragEnter = (e) => {
    if (!showImg) return;
    e.preventDefault();
    e.stopPropagation();

    // Increase the counter whenever a drag enters, whether on the parent or child
    setDragCount((prev) => prev + 1);
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    if (!showImg) return;
    e.preventDefault();
    e.stopPropagation();

    // Decrease the counter when a drag leaves
    setDragCount((prev) => {
      // If we've left entirely (counter back to zero), set isDragging to false
      const newCount = prev - 1;
      if (newCount === 0) {
        setIsDragging(false);
      }
      return newCount;
    });
  };

  const handleDragOver = (e) => {
    if (!showImg) return;
    e.preventDefault();
    e.stopPropagation();
  };

  const [imageFile, setImageFile] = useState(null);

  // Handle the file input change
  const processFile = (file) => {
    if (!showImg) return;

    if (file && file.type.startsWith('image/')) {
      if (file.size <= 1 * 1024 * 1024) {
        // If the file is <= 1MB, just use it directly
        setImageFile(file);
      } else {
        // File > 1MB: Compress using react-image-file-resizer
        const compressImage = (file, quality) => {
          Resizer.imageFileResizer(
            file,
            800,              // max width
            600,              // max height
            'JPEG',           // output format
            quality,          // quality (adjusted dynamically)
            0,                // rotation
            (uri) => {
              fetch(uri)
                .then(res => res.blob())
                .then(blob => {
                  if (blob.size / (1024 * 1024) <= 1) {
                    // Compressed file is <= 1MB, use it
                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
                    setImageFile(compressedFile);
                  } else if (quality > 10) {
                    // Retry with lower quality if file is still too large
                    compressImage(file, quality - 10);
                  } else {
                    alert('Unable to compress the image to less than 1MB.');
                  }
                });
            },
            'base64'          // output type
          );
        };

        // Start compression with initial quality of 70
        compressImage(file, 70);
      }
    } else {
      alert('Please select a valid image file');
    }
  };

  const handleDrop = (e) => {
    if (!showImg) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    processFile(droppedFile);
  };

  const [imgLoading, setImgLoading] = useState(false)

  return (
    <>
      <audio src='B.mp3' ref={recRef} />
      <audio src='A.mp3' ref={sendRef} />

      <Header />

      <div className={`body ${isWindows && "windows-os"}`}>

        <div className={`leftBar ${!closed && "closedOff"} ${shown === 0 ? "shown" : "notShown"} ${isCollapsed && "collapse"}`}>
          <Models
            setShown={setShown}
            influencerID={influencerID}
            setInfluencerID={setInfluencerID}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <History
            setShown={setShown}
            handlePen={handlePen}
            influencerID={influencerID}
            setNewID={setNewID}
            sessionID={sessionID}
            setSessionID={setSessionID}
            user={user}
            handleHistClick={handleHistClick}
          />
        </div>
        <div className={`middleBar ${!closed ? "closedOff" : "x"} ${shown === 1 ? "shown" : "notShown"}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop} style={{ position: "relative" }}>
          {showImg && isDragging && <DraggingIndicator />}
          <div className="card chat">
            <MiddleTop isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} setShown={setShown} setClosed={setClosed} influencerID={influencerID} />
            {/* <button style={{ position: "absolute", top: 0, background: streamMode ? "green" : "red" }} onClick={() /=> setStreamMode(s => !s)}>stream</button> */}
            <div className="convo" id="chatContainer">
              <ChatLog
                influencerID={influencerID}
                newID={newID} user={user} sessionID={sessionID}
                messages={messages} setMessages={setMessages}
                err={err} handleSubmit={handleSubmit} setInp={setInp}
                inpRef={inpRef} imgLoading={imgLoading} streamMode={streamMode} />
              {disabled === sessionID && !streamMode && <TypingIndicator />}
            </div>

            <Input setInp={setInp} inpRef={inpRef} user={user}
              handleSubmit={handleSubmit} inp={inp} disabled={disabled}
              setShowBrowserModal={setShowBrowserModal}

              imageFile={imageFile} setImageFile={setImageFile} processFile={processFile}
              showImg={showImg} />

          </div>
        </div>

        <RightBar
          closed={closed} influencerID={influencerID} setClosed={setClosed}
          shown={shown} setShown={setShown} testMode={testMode} setTestMode={setTestMode} />
      </div>
      <BrowserModal
        isOpen={showBrowserModal}
        onClose={() => setShowBrowserModal(false)}
      />
    </>
  )
}
