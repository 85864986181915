// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/Chat/Chat'
import { AuthContextProvider } from './AuthContextProvider';
import SubscriptionHandler from './components/Subscription/SubscriptionHandler';
import axios from 'axios';
import { getDeviceId } from './utils/deviceId';
import AffiliateHandler from './components/Affiliate/AffiliateHandler';
import PricingPage from './components/Pricing/PricingPage.tsx';
import { setAffiliateCode } from './utils/affiliateCode.js'
import CongratulationsPage from './components/Congratulations/CongratulationsPage.tsx'
import { GlobalContextProvider } from './GlobalContextProvider.js';
import CaseStudy from './components/types/CaseStudy/CaseStudy.jsx';
import AffData from './components/AffData/AffData.jsx';

function App() {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const affiliateID = urlParams.get('ref');
        
        if (affiliateID) {
            setAffiliateCode(affiliateID);
            // Send event to backend
            // logEvent(affiliateID);
            axios.post("https://api.alphatwin.ai/visit", {
                deviceID: getDeviceId(),
                ref: affiliateID
            })
                .then(res => console.log(res))
                .catch(e => console.error(e))
        }
    }, []);

    return (
        <GlobalContextProvider>
            <AuthContextProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Chat />} />
                        <Route path="/subscription" element={<SubscriptionHandler />} />
                        <Route path="/affiliate" element={<AffiliateHandler />} />
                        <Route path="/pricing" element={<PricingPage />} />
                        <Route path="/congratulations" element={<CongratulationsPage />} />
                        {/* <Route path="/aiguy-case-study" element={<CaseStudy />} /> */}
                        <Route path="/affiliate_data" element={<AffData />} />
                    </Routes>
                </Router>
            </AuthContextProvider>
        </GlobalContextProvider>
    );
}

export default App;
